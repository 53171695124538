<template>
  <suc-radio-field-component
    :title="$t('pages.payroll.state')"
    v-bind="$attrs"
    v-on="$listeners"
    :options="options"
  ></suc-radio-field-component>
</template>

<script>
import { SucRadioFieldComponent, RadioOption } from "@/components/form";

export default {
  components: {
    SucRadioFieldComponent,
  },
  data() {
    return {
      options: [
        new RadioOption({ name: this.$t("pages.payroll.signed"), value: true }),
        new RadioOption({ name: this.$t("pages.payroll.not_signed"), value: false }),
        new RadioOption({ name: this.$t("pages.payroll.all"), value: null }),
      ],
    };
  },
};
</script>

<style></style>
