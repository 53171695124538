import EmployeeListField from "@/pages/components/fields/EmployeeListField.vue";
import WorkShiftListField from "@/pages/components/fields/WorkShiftListField.vue";
import YearField from "@/pages/components/fields/YearField.vue";
import MonthField from "@/pages/components/fields/MonthField.vue";
import DayField from "@/pages/components/fields/DayField.vue";
import AppointmentStatusField from "@/pages/components/fields/AppointmentStatusField.vue";
import PayRollStatusField from "@/pages/components/fields/PayRollStatusField.vue";

export {
  EmployeeListField,
  YearField,
  MonthField,
  DayField,
  PayRollStatusField,
  WorkShiftListField,
  AppointmentStatusField,
};
