<template>
  <vue-modal
    :modal-title="$t('pages.planing-team-validate.add_workshift')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :loading-primary-footer-button="isLoading"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[secondaryEvent]="onCloseButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <div class="row mb-4" v-if="employeeInitialized">
        <div class="col">
          <h6>{{ $t("pages.planing-team-validate.employee_name") }}: {{ employee.fullName }}</h6>
        </div>
      </div>

      <suc-form-has-error :form="form" />

      <div class="row">
        <div class="col">
          <work-shift-list-field :form="form" name="calendar" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <start-date-workshift-field :form="form" name="startDate" id="startDate" />
        </div>
        <div class="col">
          <end-date-workshift-field :form="form" name="endDate" id="endDate" />
        </div>
      </div>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";

import {
  SHOW_WORK_SHIFT_ADD_MODAL,
  EMPLOYEE_WORK_SHIFT_ADDED,
} from "@/pages/responsible/planing/data/events";

import { WorkShiftListField } from "@/pages/components/fields";
import { StartDateWorkshiftField, EndDateWorkshiftField } from "./fields";

import { Form, FORM_ERRORS, SucFormHasError } from "@/components/form";
import { RequiredRule, FormRule } from "@/components/form/data/rules";

import { isAfter, isEqual } from "date-fns";
import api from "@/api/workshift";

export default {
  components: {
    VueModal,
    WorkShiftListField,
    StartDateWorkshiftField,
    EndDateWorkshiftField,
    SucFormHasError,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      employee: null,
      form: new Form({
        data: {
          calendar: null,
          startDate: null,
          endDate: null,
          error: null,
          startDateSpecified: true,
          endDateSpecified: true,
        },
      }),
    };
  },
  computed: {
    employeeInitialized() {
      return this.$isset(this.employee);
    },
    fieldsRules() {
      let rules = [
        new RequiredRule({
          name: "calendar",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
        new RequiredRule({
          name: "startDate",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
        new RequiredRule({
          name: "endDate",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
      ];
      return rules;
    },
    formRules() {
      let rules = [];
      rules.push(
        new FormRule({
          rule: (x) => {
            return isAfter(x.endDate, x.startDate) || isEqual(x.endDate, x.startDate);
          },
          errorMessage: this.$t("pages.permission.wrong_period_date"),
        })
      );
      return rules;
    },
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
  },
  created() {
    EventBus.listen(SHOW_WORK_SHIFT_ADD_MODAL, ({ employee }) => {
      this.employee = employee;
      this.showModal = true;
    });
  },
  watch: {
    async showModal(val) {
      if (val) {
        this.clear();
      }
    },
  },
  beforeDestroy() {
    EventBus.off(SHOW_WORK_SHIFT_ADD_MODAL);
  },
  methods: {
    async onPrimaryButtonClicked() {
      this.form.setFieldsRules(this.fieldsRules);
      this.form.setFormRules(this.formRules);

      if (this.form.validate()) {
        this.isLoading = true;

        var data = this.form.data();
        data["employeeCode"] = this.employee.employeeCode;

        api
          .createWorkShift(data)
          .then(() => {
            this.form.reset();
            this.showModal = false;
            this.$emit("changed");
            EventBus.fire(EMPLOYEE_WORK_SHIFT_ADDED, { employeeCode: this.employee.employeeCode });
          })
          .catch((error) => {
            const errorData = error.response.data;
            if (this.$isset(errorData.detail)) {
              this.form.errors.set(FORM_ERRORS, errorData.detail);
            }
            // if (message == "ERROR! Work shift hours exceed the daily hours of the employee card.") {
            //   this.form.errors.set(
            //     FORM_ERRORS,
            //     this.$t("pages.planing-team.workshift.errors.error_exceed_hour")
            //   );
            // }
            console.error(error.response);
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.form.errors.clear();
      this.form.reset();
    },
  },
};
</script>
