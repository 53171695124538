<template>
  <suc-select-component
    :title="$t('components.fields.status_appointment.title')"
    :options="options"
    label="name"
    :form="form"
    :name="name"
  >
    <template #no-options>{{ $t("components.fields.no_options") }}</template>
  </suc-select-component>
</template>

<script>
import { SucSelectComponent, SUC_SELECT_CHANGED_EVENT, Form } from "@/components/form";
import {
  STATUS_APPOINTMENT_CONFIRMWAITING,
  STATUS_APPOINTMENT_APPROVED,
  STATUS_APPOINTMENT_NEEDREUNION,
  STATUS_APPOINTMENT_REJECTED,
} from "@/pages/components";

export default {
  components: {
    SucSelectComponent,
  },
  props: {
    initValue: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    form: {
      type: Form,
      required: true,
    },
  },
  data() {
    return {
      options: [],
      value: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.options = [
        {
          id: STATUS_APPOINTMENT_CONFIRMWAITING,
          name: this.$t("pages.planing-team.absences.confirm_waiting"),
        },
        {
          id: STATUS_APPOINTMENT_APPROVED,
          name: this.$t("pages.planing-team.absences.approved"),
        },
        {
          id: STATUS_APPOINTMENT_NEEDREUNION,
          name: this.$t("pages.planing-team.absences.need_reunion"),
        },
        {
          id: STATUS_APPOINTMENT_REJECTED,
          name: this.$t("pages.planing-team.absences.rejected"),
        },
      ];
    },
    onChangeField(option) {
      this.form[this.name] = option;
      this.$emit(SUC_SELECT_CHANGED_EVENT, this.$deepCopy(this.value));
    },
  },
};
</script>

<style scoped></style>
