<template>
  <suc-select-component
    :title="$t('components.fields.year')"
    :options="options"
    :value="value"
    @input="onChangeField"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #no-options>{{ $t("components.fields.no_options") }}</template>
  </suc-select-component>
</template>

<script>
import { SucSelectComponent, SUC_SELECT_CHANGED_EVENT } from "@/components/form";

export default {
  components: {
    SucSelectComponent,
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    options() {
      let options = [];
      const currentYear = new Date().getFullYear();
      for (let i = 0; i < 10; i++) {
        options.push(currentYear - i);
      }

      return options;
    },
  },
  methods: {
    onChangeField(option) {
      this.value = option;
      this.$emit(SUC_SELECT_CHANGED_EVENT, this.$deepCopy(this.value));
    },
    clear() {
      this.value = null;
    },
  },
};
</script>

<style scoped></style>
